import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent"
import RelatedServices from "../components/ServiceDetails/RelatedServices"

const Ecommerce = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Ecommerce"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Ecommerce"
      />
         <ServiceDetailsContent 
          aboutService='Our professional Market Expert will solve your problem related to Online Shopping. We will provide you solution related to Amazon and Shopify Stores.'
            technologies = {['Adobe Photoshop','Adobe illustrator','Adobe XD',]}
            />      <Footer />
    </Layout>
  )
}

export default Ecommerce
